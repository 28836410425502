import React from 'react'
import AboutOne from './AboutOne'
import AboutTwo from './AboutTwo'
import Discuss from '../Home/Discuss'
import AboutThree from './AboutThree'

const About = () => {
  return (
    <div>
      <AboutOne />
      <Discuss />
      <AboutTwo />
      <AboutThree />
    </div>
  )
}

export default About
