import React from "react";
//import SEO  from "../../Assets/SEOAD.svg";
import { motion } from "framer-motion";
import { textVariant, fadeIn } from "../FramerMotion/styles";

const HomeOne = () => {
  const backgroundImageUrl =
    "https://cdn.pixabay.com/photo/2019/09/09/08/23/internet-4463031_1280.jpg";
  return (
    <div className="w-screen">
      <div className="w-full relative py-8 sm:min-h-[85vh] flex items-center justify-center">
        <div
          className="absolute top-0 w-full h-full bg-center bg-cover opacity-100 bg-black"
          style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        >
          <span className="w-full h-full absolute left-0 opacity-75 bg-black"></span>
        </div>
        <div
          className="container mx-auto relative 
      flex items-center justify-center h-full"
        >
          <div
            className="flex flex-col gap-6 
      lg:flex-row"
          >
            <div className="flex sm:w-1/2 gap-3 py-2 flex-col w-full text-white items-start justify-center px-6">
              <motion.h1
                initial="hidden"
                animate="show"
                variants={textVariant()}
                className="text-3xl sm:text-5xl text-white font-bold"
              >
                Your<span className="text-teal-300"> SEO, </span> <br />
                Google Ads, <br /> & Digital Dreams {" "} <br/>
                <span className="text-teal-300">Becomes  </span> Reality.
              </motion.h1>
              {/* <motion.h2
                initial="hidden"
                animate="show"
                variants={textVariant()}
                className="text-3xl sm:text-5xl text-white font-bold"
              >
                Where Your Digital Dreams Become Reality
              </motion.h2> */}
              <motion.p
                initial="hidden"
                animate="show"
                variants={fadeIn("up", "", 0.1, 1)}
                className="text-white font-semibold text-lg tracking-wider"
              >
                {" "}
                Hey there! Glad you stopped by. We’re DigitalTechFlux, and we’re
                all about helping your business make a big splash in the digital
                world. Think of us as your go-to pals for everything web and
                digital marketing.
              </motion.p>
              <motion.div
                initial="hidden"
                animate="show"
                variants={fadeIn("up", "", 0.3, 1)}
                className="flex gap-4 justify-center items-center"
              >
                <button
                  className="animate-bounce  w-[150px] font-semibold my-2 bg-black border border-teal-300 hover:border-black
                   rounded hover:bg-teal-300 transition duration-500 ease-in-out p-3 hover:animate-none"
                >
                  Get a Quote
                </button>
                <button
                  className="my-2 w-[120px] font-semibold bg-black border border-teal-400 
                  bg-black hover:border-black rounded hover:bg-teal-300 transition duration-500 ease-in-out p-3 "
                >
                  Subscribe
                </button>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeOne;
