import React, { useState } from "react";
//import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { textVariant, fadeIn } from "../FramerMotion/styles";
import { motion } from "framer-motion";
import { ReactComponent as Ques } from "../../Assets/questions.svg";

export default function HomeFive() {
  const lists = [
    {
      questions: " How does DigitalTechFlux approach web development?",
      answers:
        "We focus on creating custom, responsive, and user-friendly websites that meet the unique needs of each client. Our process involves understanding your business objectives, designing a layout that reflects your brand, and developing a website that is both visually appealing and functionally robust.",
    },
    {
      questions:
        "Can DigitalTechFlux help improve my website’s search engine ranking?",
      answers:
        "Absolutely! Our SEO services are designed to enhance your website’s visibility and ranking on search engines. We employ the latest SEO strategies and techniques, including keyword research, content optimization, and technical SEO, to ensure your site ranks high in search results.",
    },
    {
      questions:
        " What is staff augmentation, and how can it benefit my business?",
      answers:
        "Staff augmentation is a service where we provide skilled professionals to supplement your existing team. This can be particularly beneficial during peak periods, for special projects, or when specific expertise is required. It’s a flexible way to scale your team without the long-term commitment of hiring new employees.",
    },
    {
      questions: "Does DigitalTechFlux offer digital marketing services?",
      answers:
        "Yes, we offer a comprehensive suite of digital marketing services. This includes everything from social media management and content marketing to email campaigns and PPC advertising. Our goal is to help you reach and engage your target audience effectively.",
    },
    {
      questions: "What is content marketing, and why is it important?",
      answers:
        "Content marketing involves creating and sharing valuable, relevant content to attract and engage a defined audience. It’s important because it helps establish your brand as an authority in your field, improves engagement, and drives customer action.",
    },
    {
      questions: "How do I start a project with DigitalTechFlux?",
      answers:
        "Starting a project with us is easy! Just contact us through our website, and we’ll schedule a consultation to discuss your needs and how we can help. We’ll then provide a detailed proposal and, upon agreement, begin working on your project.",
    },
    {
      questions: "How does DigitalTechFlux ensure client satisfaction?",
      answers:
        "We prioritize open communication, transparency, and a customized approach to every project. Regular updates and feedback sessions ensure that we are always aligned with your expectations and project goals.",
    },
  ];

  const FAQ = ({ questions, answers }) => {
    const [isActive, setIsActive] = useState(false);
    return (
      <>
        <div
          className="sm:w-[80vw] relative overflow-hidden md:w-[700px] w-[95vw] flex flex-col rounded-lg
          justify-center items-center cursor-pointer border-b border-teal-300 my-2"
          onClick={() => setIsActive(!isActive)}
        >
          <motion.div
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            variants={textVariant()}
            className="w-full flex justify-between bg-white items-center sm:px-3"
          >
            <h3 className="p-3 font-semibold text-lg text-black inline-block">
              {questions}
            </h3>
            <button
              className={` ${
                isActive &&
                "rotate-45 transition transform delay-150 duration-500 ease-in-out"
              }
            pr-3 text-2xl text-black transition transform delay-150 duration-500 ease-in-out`}
              onClick={() => setIsActive(!isActive)}
            >
              {/* {isActive ? <GoChevronUp /> : <GoChevronDown />} */}+
            </button>
          </motion.div>
        </div>
        <motion.div
          initial="hidden"
          whileInView="show"
          variants={textVariant()}
          className={`${
            !isActive &&
            "hidden transition-all delay-150 ease-in-out duration-500"
          } sm:w-[80vw] overflow-hidden md:w-[700px] w-[95vw] flex flex-col rounded-lg
            justify-center items-center cursor-pointer my-2`}
        >
          <p className="px-6 text-white">{answers}</p>
        </motion.div>
      </>
    );
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="show"
      viewport={{ once: true }}
      variants={fadeIn("up", "", 0.5, 1.25)}
      className="min-h-[80vh] flex justify-center gap-5 items-center 
    flex-col lg:flex-row h-auto py-10 bg-black px-3"
    >
      <motion.div
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
        variants={textVariant()}
        className="flex flex-col justify-center items-center pb-5"
      >
        <Ques />
      </motion.div>
      <div className="flex flex-col justify-start items-start px-3">
        <h2 className="text-4xl text-white px-6 pb-3 ">
          Frequently Asked Questions
        </h2>
        {lists.map((list, index) => {
          const { questions, answers } = list;
          return <FAQ key={index} questions={questions} answers={answers} />;
        })}
      </div>
    </motion.div>
  );
}
