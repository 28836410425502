import { motion } from "framer-motion";
import { textVariant, fadeIn } from "../FramerMotion/styles";
import React, {  useState, useEffect } from "react";
import axios from "axios";
import PostCard from "../ReUse/PostCard";

const HomeSix = () => {
  const [input, setInput] = useState([]);

  useEffect(() => {
    let unSubscribe = false;
    const sendRequest = async () => {
      const res = await axios.get(process.env.REACT_APP_URL);
      const data = await res.data;
      setInput(data.allPost);
      return data;
    };
    if (!unSubscribe) {
      sendRequest();
    }
    return () => (unSubscribe = true);
  }, []);

  return (
    <div className="bg-white py-8 mx-auto">
      <motion.div
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
        variants={fadeIn("up", "", 0.25, 1.25)}
        className="container mx-auto min-h-[70vh] px-6
      flex flex-col items-start justify-center py-4"
      >
        <motion.div
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          //animate = "show"
          variants={textVariant()}
        >
          <h2 className={`text-4xl font-bold text-black`}>Our Blogs.</h2>
        </motion.div>

        <motion.p
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          variants={fadeIn("up", "", 0.1, 1)}
          className="mt-4 text-black text-[18px] tracking-wider max-w-3xl leading-[30px]"
        >
          Insights and Trends in Web and Digital Marketing.
        </motion.p>

        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-3 container mx-auto gap-3">
          {input?.slice(0, 3).map((blog, index) => {
            return <PostCard key={index} index={index} {...blog} />;
          })}
        </div>
      </motion.div>
    </div>
  );
};

export default HomeSix;
