import React from "react";
//import SEO  from "../../Assets/SEOAD.svg";
import { motion } from "framer-motion";
import { textVariant, fadeIn } from "../FramerMotion/styles";

const AboutOne = () => {
  const backgroundImageUrl =
    "https://cdn.pixabay.com/photo/2017/02/20/14/18/business-2082639_1280.jpg";
  return (
    <div className="w-screen bg-slate-100">
      <div className="w-full relative py-8 sm:min-h-[80vh] flex items-center justify-center">
        <div
          className="absolute top-0 w-full h-full bg-center bg-cover opacity-75 bg-black"
          style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        >
          <span className="w-full h-full absolute left-0 opacity-75 bg-black"></span>
        </div>
        <div className="container mx-auto relative">
          <div className="flex flex-col justify-center items-center">
            <div className="flex gap-10 py-2 flex-col text-white items-start px-6">
              <motion.h1
                initial="hidden"
                animate="show"
                variants={textVariant()}
                className="text-3xl sm:text-5xl text-white font-bold"
              >
                <span className="text-white"> </span> <br />
                Your Partner in
                <br />
                <span className="text-teal-300">Digital</span> Excellence
              </motion.h1>
              <motion.p
                initial="hidden"
                animate="show"
                variants={fadeIn("up", "", 0.1, 1)}
                className="text-white font-semibold"
              >
                We design and develop customiseable web applications and
                comprehensive <br />
                digital marketing solutions.
              </motion.p>
              <button
                className="my-2 w-[120px] font-semibold bg-black border border-teal-400 
                  bg-black hover:border-black rounded hover:bg-teal-300 transition duration-500 ease-in-out p-3 "
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
