import React from 'react';
import HomeTwo from "../Home/HomeTwo";
import HomeOne from "../Home/HomeOne";
import Maps from '../ReUse/Maps';


const Service = () => {
  return (
    <div className=''>
      <HomeOne/>
      <HomeTwo />
      <Maps />
    </div>
  )
}

export default Service
