import React from 'react';
import {ReactComponent as Rocket} from "../../Assets/seo.svg";
import { motion } from "framer-motion"
import { fadeIn } from '../FramerMotion/styles';

const Discuss = () => {
  return (
    <motion.div
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: "true" }}
      variants={fadeIn("up", "", 0.1, 1.5)}
      className="sm:bg-white py-2 sm:py-8"
    >
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row gap-8">
          <div className="flex w-full p-6">
            <Rocket />
          </div>
          <div 
            className="flex flex-col px-6 justify-center items-start w-full"
          >
            <h2 className="font-semibold text-3xl">
              Check Your Website's SEO
            </h2>
            <p className="mt-3 text-lg">
              Wanna see how your website ranks online.
            </p>
            <button className="my-3 w-[130px] font-semibold text-white bg-black border border-teal-300 hover:border-black rounded hover:bg-teal-300 transition duration-500 ease-in-out p-3">
              Check Now
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Discuss;
