import { motion } from "framer-motion";
import { textVariant, fadeIn } from "../FramerMotion/styles";

const lists = [
  {
    title: "Be Seen by Those Who Matter",
    text: `In the digital age, being visible online is like having the best storefront on the busiest street. SEO helps put your business front and center in search engine results. Think about it: when people search for what you offer, you want to be the first thing they see, right? That's what SEO does – it's like your business waving a big flag saying, "Hey, we've got exactly what you're looking for!"`,
  },
  {
    title: "Building Credibility and Trust",
    text: "Trust is everything in business. When your site ranks high in search engine results, it’s not just about visibility; it’s about credibility. High rankings signal to potential customers that you're a key player in your field. SEO helps build this trust and authority, making sure that your business isn’t just seen, but also respected.",
  },
  {
    title: "Staying Ahead in a Competitive Market",
    text: "Let's face it: the business world is a crowded place. Everyone's vying for attention, and standing out can be tough. This is where SEO comes in. By optimizing your website, you're not just playing the game; you're staying ahead of the curve. It’s like having a secret weapon that keeps you one step ahead of your competitors, ensuring that potential customers find you first.",
  },
  {
    title: "Maximizing ROI",
    text: "We all want the most bang for our buck, right? Unlike paid advertising which stops the moment you stop paying, SEO keeps on giving. It’s a long-term strategy that can provide an impressive return on investment. With SEO, you’re investing in a digital asset – your website – that continues to attract and engage customers over time, making it a cost-effective solution for sustained growth.",
  },
];

const HomeFour = () => {
  return (
    <div className="sm:bg-black sm:py-8">
      <motion.div
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
        variants={fadeIn("up", "", 0.1, 1.25)}
        className="container bg-white py-8 mx-auto min-h-[70vh] px-8
      flex flex-col items-start justify-center py-4"
      >
        <motion.div
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          variants={textVariant()}
        >
          <h2 className={`text-4xl font-semibold text-black`}>Why SEO?</h2>
        </motion.div>

    {
      lists.map((list, index) =>{
        const {title, text} = list;
        return (
          <motion.div
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            variants={fadeIn("up", "", index * 0.1, 1)}
            className="bg-white shadow-lg my-2 p-6 rounded"
            key={index}
          >
            <motion.h2
              className="mt-4 text-black text-xl tracking-wider font-semibold"
            >
              {title}
            </motion.h2>

            <motion.p
              className="mt-2 text-black text-[18px] tracking-wider max-w-6xl "
            >
              {text}
            </motion.p>
          </motion.div>
        );
      })
    }
        
      </motion.div>
    </div>
  );
};

export default HomeFour;
