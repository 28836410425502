import React from "react";
import { GoChevronDown } from "react-icons/go";
import { NavLink } from "react-router-dom";
import { authActions } from "../../Store";
import { IoIosAddCircle } from "react-icons/io";
import logo from "../../Assets/logo.png"

const DesktopMenu = ({ onDropdownToggle, isDropdownOpen, userId, dispatch }) => {
  return (
    <div className="lg:container mx-auto flex justify-between items-center">
      <NavLink
        className="flex gap-1 justify-center items-center pl-6 sm:pl-2"
        to={userId ? "" : "/"}
      >
        {/* <IoLogoAppleAr size={36} color="" className="text-teal-400" /> */}
        <img src={logo} alt="digitaltechflux" className="w-[50px] h-[50px] rounded-full bg-transparent" />
        <div className="flex flex-col">
          <h2 className="text-base text-white font-semibold">
            {" "}
            Digital <span className="text-teal-400">TechFlux</span>{" "}
          </h2>
          <span className="text-xs text-white">Your Marketing Guru</span>
        </div>
      </NavLink>
      <ul className="hidden md:flex justify-center items-center space-x-2">
        <li>
          <NavLink
            to={userId ? "/posts" : "/"}
            className={({ isActive }) =>
              isActive
                ? "block w-[80px] text-center text-white bg-teal-400 p-2 px-2 font-semibold rounded transition ease-in duration-500"
                : "block w-[80px] text-center p-2 px-2 text-white font-semibold"
            }
          >
            {userId ? "Posts" : "Home"}
          </NavLink>
        </li>
        <li className="cursor-pointer">
          <NavLink
            to={userId ? "/add" : "/about"}
            className={({ isActive }) =>
              isActive
                ? "flex justify-center items-center gap-1 w-[80px] text-center text-white bg-teal-400 p-2 px-2 font-semibold rounded transition ease-in duration-500"
                : "flex justify-center items-center gap-1 w-[80px] text-center p-2 px-2 text-white font-semibold transition ease-in duration-500"
            }
          >
            {userId && <IoIosAddCircle />} {userId ? "Add" : "About"}
          </NavLink>
        </li>
        {!userId && (
          <li className="flex relative group">
            <button
              onClick={onDropdownToggle}
              className="mr-1 ml-2 flex justify-center items-center gap-1 text-white font-semibold"
            >
              Services <GoChevronDown className="text-teal-400" />
            </button>
            {isDropdownOpen && (
              <ul
                className="absolute bg-white p-3 w-[220px] top-6 transform scale-0  rounded
              group-hover:scale-100 transition duration-150 ease-in-out origin-top shadow-lg"
              >
                <li className="text-sm hover:bg-teal-400 rounded pl-3 hover:text-white leading-8">
                  <NavLink to="/services">All Services</NavLink>
                </li>

                <li className="text-sm leading-8 font-normal rounded hover:bg-teal-400 hover:text-white">
                  <NavLink to="/services/web" className="block pl-6 p-2">
                    Web Development
                  </NavLink>
                </li>
                <li className="text-sm leading-8 font-normal rounded hover:bg-teal-400 hover:text-white">
                  <NavLink to="/services/seo" className="block pl-6 p-2">
                    SEO
                  </NavLink>
                </li>
                <li className="text-sm leading-8 font-normal rounded hover:bg-teal-400 hover:text-white">
                  <NavLink to="/services/content" className="block pl-6 p-2">
                    Content Marketing
                  </NavLink>
                </li>
                <li className="text-sm leading-8 font-normal rounded hover:bg-teal-400 hover:text-white">
                  <NavLink to="/services/ppc" className="block pl-6 p-2">
                    PPC Advertising
                  </NavLink>
                </li>
                <li className="text-sm leading-8 font-normal rounded hover:bg-teal-400 hover:text-white">
                  <NavLink to="/services/staff" className="block pl-6 p-2">
                    Staff Augmentation
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
        )}

        {!userId && (
          <li>
            <NavLink
              to={"/blog"}
              className={({ isActive }) =>
                isActive
                  ? "block w-[80px] text-center text-white font-semibold bg-teal-400 p-2 px-2 rounded transition ease-out duration-500"
                  : "block w-[80px] text-center p-2 px-2 font-semibold text-white"
              }
            >
              Blog
            </NavLink>
          </li>
        )}

        {!userId && (
          <li>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive
                  ? "block w-[80px] text-center text-white font-semibold bg-teal-400 p-2 px-2 rounded transition ease-out duration-500"
                  : "block w-[80px] text-center p-2 px-2 text-white font-semibold"
              }
            >
              Contact
            </NavLink>
          </li>
        )}

        {!userId ? (
          <li>
            <button
              className="block w-[120px] text-center text-white font-semibold hover:bg-cyan-600 
          bg-cyan-400 transition ease-in-out duration-500 border border-black p-2 px-2 rounded transition ease-out duration-500"
            >
              Get a Quote
            </button>
          </li>
        ) : (
          <li>
            <NavLink
              to={"/login-staff"}
              onClick={() => dispatch(authActions.logout())}
              className="block w-[120px] text-center text-white font-semibold hover:bg-cyan-600 
          bg-cyan-400 transition ease-in-out duration-500 border border-black p-2 px-2 rounded transition ease-out duration-500"
            >
              Logout
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
};

export default DesktopMenu;
