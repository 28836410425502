import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { motion }from "framer-motion";
import { fadeIn, textVariant } from "../FramerMotion/styles";
import PostCard from "../ReUse/PostCard";

const Pages = () => {
  const { id } = useParams();
  const [input, setInput] = useState([]);
  const [sides, setSides] = useState([]);
  

  useEffect(() => {
    let unSubscribe = false;
    const sendRequest = async () => {
      const res = await axios.get(`${process.env.REACT_APP_URL}${id}`);
      const data = await res.data;
      //console.log(data);
      setInput(data.postById);
      return data;
    };
    if (!unSubscribe) {
      sendRequest();
    }
    //clean up function
    return () => (unSubscribe = true);
  }, [id]);

   useEffect(() => {
     let unSubscribe = false;
     const sendRequest = async () => {
       const res = await axios.get(process.env.REACT_APP_URL);
       const data = await res.data;
       //console.log(data);
       setSides(data.allPost);
       return data;
     };
     if (!unSubscribe) {
       sendRequest();
     }
     //clean up function
     return () => (unSubscribe = true);
   }, []);

  return (
    <div className="bg-white">
      <div className="container py-20 max-w-screen-xl mx-auto ">
        <div className="flex gap-3 flex-col md:flex-row">
          <div className="w-full border">
            <div className="w-full relative py-8 sm:min-h-[80vh] flex items-center justify-center">
              <div
                className="absolute top-0 w-full h-full bg-center bg-cover  bg-black"
                // style={{ backgroundImage: `url(${backgroundImageUrl})` }}
                style={{ backgroundImage: `url(${input?.imageUrl})` }}
              >
                {/* <span className="w-full h-full absolute left-0 opacity-75 bg-black"></span> */}
              </div>
            </div>
            <div className="p-6 flex flex-col gap-5">
              <motion.h1
                initial="hidden"
                animate="show"
                variants={textVariant()}
                className="text-xl sm:text-4xl text-black font-bold"
              >
                {input?.title}
              </motion.h1>
              <motion.p
                initial="hidden"
                animate="show"
                variants={fadeIn("up", "", 0.1, 1)}
                className="text-black max-w-screen-lg font-semibold tracking-wider"
              >
                {input?.description}
              </motion.p>
              <motion.h2
                initial="hidden"
                animate="show"
                variants={fadeIn("up", "", 0.1, 1)}
                className="text-lg sm:text-xl text-black font-bold"
              >
                {input?.subTitle}
              </motion.h2>
              <motion.p
                initial="hidden"
                animate="show"
                variants={fadeIn("up", "", 0.1, 1)}
                className="text-black max-w-screen-lg font-semibold tracking-wider"
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Doloribus eos earum iusto quo, necessitatibus ducimus amet quis
                quibusdam consequatur, atque voluptate, impedit nemo. Soluta
                itaque deserunt adipisci repellat hic aliquid. ||{" "}
                {input?.subDescription}
              </motion.p>
            </div>
          </div>

          <div className="w-full md:w-4/12 border flex flex-col gap-2 bg-white">
            {
              sides?.map((side, index) =>{
                return (
                  <PostCard key={index} {...side} />
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pages;
