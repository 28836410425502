import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../FramerMotion/styles";

const lists = [
  {
    title: "Expertise in Web Development",
    text: "Our team of skilled developers specializes in crafting websites that are not only aesthetically pleasing but also highly functional.",
  },
  {
    title: "Comprehensive Digital Marketing Services",
    text: " From social media management and content marketing to PPC advertising and email campaigns, we create bespoke strategies that effectively engage your audience and drive conversions.",
  },
  {
    title: "SEO - Optimizing Your Digital Reach",
    text: "Our SEO experts employ the latest strategies and tools to enhance your website's visibility, ensuring it ranks high on search engine results pages.",
  },
];

const HomeThree = () => {
  return (
    <motion.div
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: true }}
      variants={fadeIn("up", "", 0.1, 1.25)}
      className="bg-white flex justify-center items-center py-8"
    >
      <div className="container mx-auto p-6">
        <div className="flex flex-col gap-5">
          <h2 className="text-5xl font-bold">Choosing us</h2>
          <p className="text-lg tracking-wider pl-2 max-w-screen-sm">
            We focus on creating impactful digital experiences that resonate
            with your target audience and foster sustainable growth.
          </p>
        </div>
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">

          {
            lists.map((list, index)=>{
              const {title, text} = list;
              return (
                <div
                  key={index}
                  className="border bg-white text-black shadow-lg p-3
                        rounded cursor-pointer m-2"
                >
                  <h2 className="text-3xl font-medium pb-2">
                    {title}
                  </h2>
                  <p className="tracking-wider">
                    {text}
                  </p>
                </div>
              );
            })
          }         
        </div>
      </div>
    </motion.div>
  );
};

export default HomeThree;
