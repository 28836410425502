import React, { useRef, useState } from "react";
import { ReactComponent as Rocket } from "../../Assets/seo.svg";
//import emailjs from "@emailjs/browser";
//import { styles } from "../styles";
//import { SectionWrapper } from "../hoc";


const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    text: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // emailjs
    //   .sendForm(
    //     import.meta.env.VITE_SERVICE_ID,
    //     import.meta.env.VITE_TEMP_ID,
    //     e.target,
    //     import.meta.env.VITE_API_KEY
    //   )
    //   .then(
    //     () => {
    //       setLoading(false);
    //       alert("Thank you. I will get back to you as soon as possible.");

    //       setForm({
    //         name: "",
    //         email: "",
    //         text: "",
    //       });
    //     },
    //     (error) => {
    //       setLoading(false);
    //       console.error(error);

    //       alert("Ahh, something went wrong. Please try again.");
    //     }
    //   );
  };

   const backgroundImageUrl =
     "https://cdn.pixabay.com/photo/2017/09/28/08/31/letters-2794672_1280.jpg";

  return (
    <>
      <div className="container mt-8 mx-auto">
        <div className="w-full relative py-8 sm:min-h-[35vh] flex items-center justify-center">
          <div
            className="absolute top-0 w-full h-full rounded-lg bg-center bg-cover opacity-75 bg-black"
            // style={{ backgroundImage: `url(${backgroundImageUrl})` }}
            style={{ backgroundImage: `url(${backgroundImageUrl})` }}
          >
            <span className="w-full h-full absolute rounded-lg left-0 opacity-75 bg-black"></span>
          </div>
        </div>
      </div>
      <div
        className={`container min-h-screen mx-auto flex flex-col  lg:flex-row py-12 overflow-hidden`}
      >
        <div className="w-full flex justify-center p-6 items-center ">
          <Rocket />
        </div>
        <div className="w-full  bg-white p-8 rounded-2xl">
          <h3 className={`text-5xl font-semibold text-primary`}>
            Get In Touch.
          </h3>
          {/* <p className={` text-primary `}>
            Or Send an{" "}
            <span
              onClick={() => (window.location = "mailto:pingtosudip@gmail.com")}
              className="text-teal-300 cursor-pointer underline "
            >
              Email
            </span>{" "}
            Instead.
          </p> */}

          <form
            ref={formRef}
            onSubmit={handleSubmit}
            className="mt-8 flex flex-col gap-8"
          >
            <label className="flex flex-col">
              <span className="text-black font-medium mb-4">Your Name</span>
              <input
                type="text"
                name="name"
                value={form.name}
                onChange={handleChange}
                placeholder="May I know who I am speaking with?"
                required
                className="bg-black py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border focus:border-teal-300 font-medium"
              />
            </label>
            <label className="flex flex-col">
              <span className="text-black font-medium mb-4">Your email</span>
              <input
                type="email"
                name="email"
                value={form.email}
                required
                onChange={handleChange}
                placeholder="What's your email address?"
                className="bg-black py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border focus:border-teal-300 font-medium"
              />
            </label>
            <label className="flex flex-col">
              <span className="text-black font-medium mb-4">Your Message</span>
              <textarea
                rows={7}
                name="text"
                value={form.text}
                required
                onChange={handleChange}
                placeholder="Could you share your viewpoint?"
                className="bg-black py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border focus:border-teal-300 font-medium"
              />
            </label>

            <button
              type="submit"
              className="bg-teal-500 hover:bg-teal-400 py-3 px-8 rounded-xl outline-none w-fit text-white font-bold shadow-md shadow-primary"
            >
              {loading ? "Sending..." : "Send"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
