import { useState, useEffect } from "react";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { RxHamburgerMenu, RxCross2 } from "react-icons/rx";
import { NavLink as Link } from "react-router-dom";
import { authActions } from "../../Store";

const MobileMenu = ({
  onDropdownToggle,
  isMenuOpen,
  onMenuToggle,
  isDropdownOpen,
  userId,
  dispatch,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="md:hidden flex justify-center mt-3  mx-auto bg-black">
      <div className="absolute z-10 left-0 top-23 bg-black w-full">
        <button
          onClick={onMenuToggle}
          className={`fixed top-0 right-0 duration-300 m-4 text-white cursor-pointer ${
            isScrolled ? "hidden" : ""
          }`}
        >
          {isMenuOpen ? (
            <RxCross2 size={"30px"} />
          ) : (
            <RxHamburgerMenu size={"30px"} />
          )}
        </button>

        {isMenuOpen && (
          <div className="flex flex-col shadow-lg leading-9 font-bold min-h-[60vh] h-auto pt-3">
            <div
              className="
              flex flex-col 
               shadow-lg  min-h-[80vh] h-auto pt-3"
            >
              <Link
                onClick={onMenuToggle}
                to={userId ? "/posts" : "/"}
                className="block p-2 pl-5 cursor-pointer text-white hover:bg-teal-400 duration-400 ease-in hover:text-white"
              >
                {userId ? "Posts" : "Home"}
              </Link>
              <Link
                onClick={onMenuToggle}
                to={userId ? "/add" : "/about"}
                className="block p-2 pl-5 cursor-pointer text-white hover:bg-teal-400 duration-400 ease-in hover:text-white"
              >
                {userId ? "Add" : "About"}
              </Link>

              {!userId && (
                <button
                  onClick={onDropdownToggle}
                  className="duration-300 flex gap-1 justify-start text-white items-center p-2 pl-5 hover:bg-teal-400 duration-400 ease-in hover:text-white"
                >
                  Services{" "}
                  {isDropdownOpen ? (
                    <GoChevronUp size={"20px"} />
                  ) : (
                    <GoChevronDown size={"20px"} />
                  )}
                </button>
              )}
              {isDropdownOpen && (
                <ul className="bg-white text-gray-800 w-full pb-1">
                  <li className="text-sm leading-8 font-normal">
                    <Link
                      onClick={onMenuToggle}
                      className="block pl-5 p-2 hover:bg-teal-400 duration-400 ease-in hover:text-white"
                      to="/services"
                    >
                      All Services
                    </Link>
                  </li>
                  <li className="text-sm leading-8 font-normal">
                    <Link
                      onClick={onMenuToggle}
                      className="block pl-10 p-2 hover:bg-teal-400 duration-400 ease-in hover:text-white"
                      to="/services/web"
                    >
                      Web Development
                    </Link>
                  </li>
                  <li className="text-sm leading-8 font-normal duration-400 ease-in hover:bg-teal-400 hover:text-white">
                    <Link
                      onClick={onMenuToggle}
                      className="block pl-10 p-2"
                      to="/services/seo"
                    >
                      SEO
                    </Link>
                  </li>
                  <li className="text-sm leading-8 font-normal duration-400 ease-in hover:bg-teal-400 hover:text-white">
                    <Link
                      onClick={onMenuToggle}
                      className="block pl-10 p-2"
                      to="/services/content"
                    >
                      Content Marketing
                    </Link>
                  </li>
                  <li className="text-sm leading-8 font-normal duration-400 ease-in hover:bg-teal-400 hover:text-white">
                    <Link
                      onClick={onMenuToggle}
                      className="block pl-10 p-2"
                      to="/services/ppc"
                    >
                      PPC Advertising
                    </Link>
                  </li>
                  <li className="text-sm leading-8 font-normal duration-400 ease-in hover:bg-teal-400 hover:text-white">
                    <Link
                      onClick={onMenuToggle}
                      className="block pl-10 p-2"
                      to="/services/staff"
                    >
                      Staff Augmentation
                    </Link>
                  </li>
                </ul>
              )}

              {!userId && (
                <Link
                  onClick={onMenuToggle}
                  to="/blog"
                  className="block p-2 text-white pl-5 hover:bg-teal-400 duration-400 ease-in hover:text-white"
                >
                  Blog
                </Link>
              )}

              {!userId && (
                <Link
                  onClick={onMenuToggle}
                  to="/contact"
                  className="block p-2 text-white pl-5 hover:bg-teal-400 duration-400 ease-in hover:text-white duration-3000 "
                >
                  Contact
                </Link>
              )}

              {userId && (
                <Link
                  onClick={() => dispatch(authActions.logout())}
                  to={"/login-staff"}
                  className="block p-2 text-white pl-5 hover:bg-teal-400 duration-400 ease-in hover:text-white duration-3000 "
                >
                  Logout
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileMenu;
