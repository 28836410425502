export const services = [
  {
    title: "Web Development",
    desc: "Need a website that wows your visitors?. We build everything from sleek, responsive websites to complex e-commerce platforms.",
    link: "/web",
  },
  {
    title: "SEO",
    desc: "Ever wonder how to get your website to the top of Google search results? That’s where our SEO expertise comes in.",
    link: "/seo",
  },
  {
    title: "Staff Augmentation",
    desc: "Need an extra pair of hands? Our staff augmentation service lets you borrow our top-notch professionals for your projects. ",
    link: "/staff",
  },
  {
    title: "Digital Marketing",
    desc: "From eye-catching social media campaigns to email marketing that your customers will actually want to read – we do it all. ",
    link: "/marketing",
  },
  {
    title: "Content Marketing",
    desc: "Great content tells your story, vision, and gets people excited about what you do and impact you make in people lifes.",
    link: "/content",
  },
  {
    title: "Pay Per Click",
    desc: "We create and manage campaigns that not only reach but also resonate with your desired audience, ensuring optimal results.",
    link: "/ppc",
  },
];

export const blogs = [
  {
    title: "The Human Element: Managing IT Teams Effectively",
    link: "/blog/5",
    description:
      "Navigating the synergy of human dynamics in IT team management effectively...",
    icon: "https://cdn.pixabay.com/photo/2020/05/18/16/17/social-media-5187243_1280.png",
    day: 28,
    month: "June",
  },
  {
    title: "Beyond SEO: The Future of Search Engine Marketing",
    link: "/blog/6",
    description:
      "Exploring the future landscape of search engine marketing beyond traditional SEO strategies...",
    icon: "https://images.unsplash.com/photo-1562577309-2592ab84b1bc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2VvfGVufDB8fDB8fHww",
    day: 28,
    month: "Sep",
  },
  {
    title: "Overview of Ethical Hacking Tools",
    link: "/blog/7",
    description:
      "Explore ethical hacking tools for a comprehensive overview of cybersecurity measures and practices ...",
    icon: "https://plus.unsplash.com/premium_photo-1676618540046-3a68a4e20408?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjF8fHNlY3VyaXR5fGVufDB8fDB8fHww",
    day: 28,
    month: "Oct",
  },
  {
    title: "Introduction to Network",
    link: "/blog/8",
    description:
      "An introduction to networks: Understanding the fundamentals of communication and connectivity in modern computing environments ...",
    icon: "https://images.unsplash.com/photo-1544197150-b99a580bb7a8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bmV0d29ya3xlbnwwfHwwfHx8MA%3D%3D",
    day: 28,
    month: "Nov",
  },
];

export const pages = [
  {
    id: "web",
    image: "",
    title: "",
    paragraph: ["lorem", " lorem"],
    about: "",
    skills: ["", ""],
    
  },
];

