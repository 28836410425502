import {motion} from "framer-motion";
import { fadeIn } from "../FramerMotion/styles";
import { services } from "../Constant";
import { IoIosArrowRoundForward } from "react-icons/io"
import { useNavigate } from "react-router-dom";

const HomeTwo = () => {

  const navigate = useNavigate();

  return (
    <div className="sm:bg-black sm:py-24">
      <motion.div
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true }}
        variants={fadeIn("up", "", 0.1, 1.25)}
        className="py-8 container mx-auto bg-white md:px-8"
      >
        <div
          className="container mx-auto min-h-[70vh] px-6
      flex flex-col items-start justify-center py-4"
        >
          <motion.div
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            variants={fadeIn("up", "", 0.1, 1)}
          >
            <h2 className={`text-4xl font-semibold text-black`}>
              Our Services.
            </h2>
          </motion.div>

          <motion.p
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            variants={fadeIn("up", "", 0.1, 1)}
            className="mt-4 text-black text-[18px] tracking-wider max-w-3xl leading-[28px]"
          >
            We focus on creating impactful digital experiences that resonate
            with your target audience and foster sustainable growth.
          </motion.p>

          <motion.div
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            variants={fadeIn("up", "", 1, 0.75)}
            className="mt-8 grid grid-cols-1 sm:grid-cols-3 gap-6"
          >
            {services.map((service, index) => {
              const { title, desc, link } = service;
              return (
                <div
                  key={index}
                  className="bg-white shadow-lg p-3 hover:bg-white transition border hover:border-teal-300
                        rounded-lg ease-in-out duration-500 delay-100 cursor-pointer "
                >
                  <h2 className="text-3xl text-black font-medium pb-2">
                    {" "}
                    {title}{" "}
                  </h2>
                  <p className="text-black text-[18px] tracking-wider max-w-3xl leading-[28px]">
                    {" "}
                    {desc}{" "}
                  </p>
                    <button
                      onClick={() => navigate(link)}
                      className="bg-black flex justify-center items-center cursor-pointer
                        hover:border-black rounded hover:bg-teal-300 transition duration-500 ease-in-out
                        gap-2 border text-white p-3 mt-2 w-[140px] 
                        
                        "
                    >
                      Learn More <IoIosArrowRoundForward size={"20px"} />
                    </button>
                </div>
              );
            })}
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}

export default HomeTwo
