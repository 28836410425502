import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import {motion} from "framer-motion";
import { fadeIn } from "../FramerMotion/styles";


// Define your Google Maps settings
const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "280px",
};
const center = {
  lat: -33.861371,
  lng: 151.203187,
};

export default function Maps() {


  // Load the Google Maps script
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC1WxhAxPt8jCkc1P71HcbFcN57Z20XwD0",
    //process.env.REACT_APP_GOOGLE_API, //process.env.REACT_APP_GOOGLE_MAPS_API_KEY Use environment variable for API key
    libraries,
  });

  if (loadError)
    return (
      <div className="w-full h-screen flex justify-center items-center">
        Error loading maps...
      </div>
    );
  if (!isLoaded)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        Loading maps...
      </div>
    );

  return (
    <div className="bg-white flex justify-center items-center min-h-[60vh] h-auto py-12">
      <motion.div
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
        variants={fadeIn("up", "", 0.1, 1.25)}
        className="container mx-auto shadow-lg"
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={10}
          center={center}
        >
          <Marker position={center} />
        </GoogleMap>
      </motion.div>
    </div>
  );
}
