import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "../../Store/index.js";
import {toast} from "react-toastify";


const Login = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [input, setInput] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    sendRequest();
  };

  const sendRequest = async() => {

    setLoading(true);
    try{
        const res = await axios.post(`${process.env.REACT_APP_USER}login`, {
          email: input.email.toLowerCase(),
          password: input.password,
        });
        const data = await res.data;
        toast.success("Login success.")
        if(data){
          data && localStorage.setItem("id", data.id);
          data && localStorage.setItem("token", data.token);
          dispatch(authActions.login({}));
          navigate('/posts')
        }        
    }
    catch(err){
        setError(true);
        return null;
    }
    finally{
        setLoading(false);
    }


  }

  const handleChange = (e) => {
    e.preventDefault();
     setInput({ ...input, [e.target.name]: e.target.value });
  }

  return (
    <div className="w-screen">
      <div className="container flex mx-auto min-h-screen justify-center items-center flex-col">
        {/* <h1 className="font-bold text-3xl sm:text-5xl py-4">Login</h1> */}
        <form
        onSubmit={handleSubmit}
          className="w-full px-6 sm:w-[500px] bg-black text-white rounded-lg min-h-[500px] flex flex-col justify-center items-start gap-5 p-6"
          action=""
        >
          <div className="flex w-full flex-col gap-3">
            <label className="text-lg font-semibold">Email: </label>
            <input
              onChange={handleChange}
              className="outline-none p-3 text-black text-lg rounded w-full"
              type="email"
              name="email"
              value = {input.email}
              required
              placeholder="Enter your Email"
            />
          </div>
          <div className="flex w-full flex-col gap-3">
            <label className="text-lg font-semibold">Password: </label>
            <input
              onChange={handleChange}
              className="outline-none text-black text-lg  p-3 rounded w-full"
              type="password"
              name="password"
              value={input.password}
              required
              placeholder="Enter your Password"
            />
          </div>
          {error && <span className="text-red-600 text-sm">Invalid Username or Password!</span>}
          <button
            onSubmit={handleSubmit}
            className="p-3 mt-2 rounded border border-teal-300 w-[140px] text-center text-2xl font-bold bg-black text-white transition hover:border-black duration-500 ease-in-out hover:bg-teal-300"
          >
            {loading ? 'Loading...' : "Login"}
          </button>
          <button>New Here ? Ask your admin for your account.</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
