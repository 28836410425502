import React from "react";
import { motion } from "framer-motion";
import { textVariant, fadeIn } from "../FramerMotion/styles";


const lists = [
  {
    title: "Our Mission",
    text: "At DigitalTechFlux, our mission is to drive digital transformation for businesses of all sizes. We believe that in the ever-evolving digital landscape, every business deserves to have a strong online presence. Our goal is to make this accessible and effective, ensuring that our clients not only survive but thrive in the digital realm.",
  },
  {
    title: "Our Services",
    text: "We specialize in a range of digital services designed to meet the diverse needs of our clients. From crafting bespoke websites to formulating targeted digital marketing strategies, our offerings include web development, SEO, staff augmentation, content marketing, PPC advertising, and much more. Each service is tailored to deliver measurable results and drive business growth.",
  },
  {
    title: "Client Relationships",
    text: "Your success is our success. This belief is at the heart of our approach to client relationships. We view each project as a partnership, where open communication, mutual respect, and shared goals are key. We listen, we understand, and we deliver, ensuring that each client’s unique needs are met with bespoke solutions.",
  },
];

const AboutTwo = () => {
  return (
    <div className="bg-black flex justify-center items-center py-8">
      <div className="container mx-auto p-6">
        <motion.div
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          variants={textVariant()}
          className="flex flex-col gap-5"
        >
          <h2 className="text-5xl text-white font-semibold">
            Our Story
          </h2>
          <p className="text-lg text-white tracking-wider max-w-3xl  pl-2">
            Founded with a passion for digital excellence, DigitalTechFlux
            comprises a team of seasoned professionals dedicated to delivering
            exceptional web and marketing solutions. Our journey began with a
            vision to revolutionize the digital space, offering unparalleled
            service in web development, SEO optimization, and innovative digital
            marketing strategies. Trust us to be your guide in the digital
            landscape.
          </p>
        </motion.div>
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">

          {
            lists.map((list, index) =>{
              const {title, text} = list;
              return (
                <motion.div
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true }}
                  variants={fadeIn("down", "spring", index * 0.55, 0.65)}
                  className="border border-teal-300 shadow bg-white text-white p-3
                        rounded cursor-pointer m-2"
                >
                  <h2 className="text-3xl text-black font-medium pb-2">
                    {title}
                  </h2>
                  <p className="font-semibold text-black tracking-wider">
                   {text}
                  </p>
                </motion.div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
