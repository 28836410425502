import { memo } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../FramerMotion/styles";
import { NavLink as Link } from "react-router-dom";

const PostCard = memo(
  ({ _id, title, description, subTitle, subDescription, imageUrl, index }) => (
    <motion.div
      initial="hidden"
      whileInView="show"
      viewport={{ once: true }}
      // onClick={() => window.open("__blank", link)}
      variants={fadeIn("up", "", index * 0.15, 1.25)}
      className="rounded overflow-hidden shadow-lg cursor-pointer pb-2 border-emerald-300 border border-2"
    >
      <div className="relative">
        <img
          className="w-full h-[200px] object-cover"
          loading="lazy"
          src={imageUrl}
          alt={title}
        />
        <div className="transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-50 hover:opacity-25 cursor-pointer"></div>
        <div className="text-sm absolute top-0 right-0 bg-teal-400 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-black transition duration-500 ease-in-out">
          {/* <span className="font-bold">{day}</span>
          <small>{month}</small> */}
        </div>
      </div>
      <div className="px-6 py-2">
        <h2 className="font-semibold text-lg inline-block ">{title}</h2>
        <p className="text-black tracking-wider font-semibold py-1">
          {description.substr(0, 150)}....
        </p>
        <Link
          to={`/blogs/${_id}`}
          className=" inline-block mt-3 w-[130px] font-semibold text-white bg-black border border-teal-300 hover:border-black
           rounded hover:bg-teal-300 transition duration-500 ease-in-out p-3 text-center"
        >
          Read Now
        </Link>
      </div>
    </motion.div>
  )
);

export default PostCard
