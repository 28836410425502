import React from "react";
import { Link } from "react-router-dom";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillInstagram,
} from "react-icons/ai";

export default function Footer() {
  const scrollToTop = () => {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const now = new Date();
  const year = now.getFullYear();

  return (
    <footer className="bg-black text-white pt-12 pb-8 px-4">
      <div className="container overflow-hidden flex flex-col lg:flex-row justify-between mx-auto px-4">
        <h2 className="block flex flex-col mr-2 w-30 text-2xl font-serif font-bold">
          DigitalTechFLux
          <span className="text-sm">Your Marketing Guru.</span>
        </h2>

        <div className="w-3/4 block sm:flex text-sm mt-6 lg:mt-0">
          <ul className="list-none p-0 font-thin flex flex-col text-left w-full text-white">
            <li className="uppercase font-bold text-white tracking-wide">
              Home Links
            </li>
            <li className="py-1 text-gray-400 text-sm font-semibold">
              <Link
                className="cursor-pointer hover:text-white"
                activeclass="active"
                to="/"
                onClick={scrollToTop}
              >
                Home
              </Link>
            </li>
            <li className="py-1 text-gray-400 text-sm font-semibold">
              <Link
                className="cursor-pointer hover:text-white"
                activeclass="active"
                to="about"
                onClick={scrollToTop}
              >
                About
              </Link>
            </li>
          </ul>

          <ul className="list-none p-0 font-thin flex flex-col text-left w-full text-white">
            <li className="uppercase font-medium text-white tracking-wide">
              Other Links
            </li>
            <li className="py-1 text-gray-400 text-sm font-semibold">
              <Link
                className="cursor-pointer hover:text-white"
                activeclass="active"
                to="services"
                onClick={scrollToTop}
              >
                Services
              </Link>
            </li>
            <li className="py-1 text-gray-400 text-sm font-semibold">
              <Link
                className="cursor-pointer hover:text-white"
                activeclass="active"
                to="contact"
                onClick={scrollToTop}
              >
                Contact
              </Link>
            </li>
          </ul>

          <div className="flex flex-col w-full text-white">
            <div className="uppercase font-medium text-white tracking-wide">
              Follow Us
            </div>
            <div className="flex mt-2">
              <Link
                activeclass="active"
                target="_blank"
                to=""
                className="flex items-center hover:text-teal-300 mr-6"
              >
                <AiFillFacebook className="w-5 h-5" />
              </Link>
              <Link
                activeclass="active"
                target="_blank"
                to=""
                className="flex items-center hover:text-teal-300 mr-6"
              >
                <AiFillTwitterSquare className="w-5 h-5" />
              </Link>
              <Link
                activeclass="active"
                target="_blank"
                to=""
                className="flex items-center hover:text-teal-300"
              >
                <AiFillInstagram className="w-5 h-5" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-4 container mx-auto mt-4 pt-6 text-gray-400 border-t border-gray-400 flex flex-col md:flex-row justify-center items-center">
        <div>
          Powered By{" "}
          <a
            className="text-white"
            target="_blank"
            href="www.digitaltechflux.com"
          >
            Digital Tech Flux
          </a>{" "}
          © {year}.
        </div>
      </div>
    </footer>
  );
}
