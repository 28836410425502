import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink as Link } from 'react-router-dom';
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { MdRemoveRedEye } from "react-icons/md";
import { toast } from "react-toastify";

const RePost = ({_id, title, imageUrl, description, subTitle, subDescription, users}) => {

const navigate = useNavigate();

const userId = localStorage.getItem("id");
const [isWarningVisible, setIsWarningVisible] = useState(false);
const token = localStorage.getItem("token");
const [error, setError] = useState(null)

const handleDelete = async() =>{
  
try{
        await axios.delete(`${process.env.REACT_APP_URL}delete/${_id}`, {
          headers: { authorization: token },
        });        
        window.location.reload();        
        navigate("/posts");
        toast.success("post deleted successfully");
        
    }
    catch(error){
        setError(error.text);
    }
}
    
const handleEdit = () =>{
    
  navigate(`/update/${_id}`)

}

  return (
    <div className="border rounded bg-white pb-3">
      <div className="w-full">
        <img alt={title} src={imageUrl} />
      </div>
      <div className="flex flex-col gap-2 p-3">
        <h2 className="font-semibold text-xl">{title}</h2>
        <p className="tracking-wider tracking-[3px] text-lg">
          {description.substr(0, 80)} ...
        </p>
        <h2 className="font-semibold text-xl">{subTitle}</h2>
        <p className="tracking-wider tracking-[3px] text-lg">
          {subDescription.substr(0, 80)} ...
        </p>
        <div className="flex gap-3 mt-2">
          {users[0] === userId && (
            <button
              onClick={handleEdit}
              className="flex justify-center items-center gap-2 mt-3 w-[130px] font-semibold text-white bg-black border border-teal-300 hover:border-black
           rounded hover:bg-teal-300 transition duration-500 ease-in-out p-3 text-center"
            >
              {<AiFillEdit />} Edit
            </button>
          )}
          {users[0] === userId && (
            <button
              onClick={() => setIsWarningVisible(true)}
              className="flex justify-center items-center gap-2  mt-3 w-[130px] font-semibold text-white bg-black border border-teal-300 hover:border-black
           rounded hover:bg-teal-300 transition duration-500 ease-in-out p-3 text-center"
            >
              {<AiFillDelete />} Delete
            </button>
          )}
          <Link
            to={`/blogs/${_id}`}
            className="w-fit flex justify-center items-center gap-2  mt-3 w-[130px] font-semibold text-white bg-black border border-teal-300 hover:border-black
           rounded hover:bg-teal-300 transition duration-500 ease-in-out p-3 text-center"
          >
            <MdRemoveRedEye />Read
          </Link>
        </div>
        {isWarningVisible && (
          <WarningBox
            onConfirm={handleDelete}
            onCancel={() => setIsWarningVisible(false)}
          />
        )}
        {error && <div> {error} </div>}
      </div>
    </div>
  );
}

function WarningBox({ onConfirm, onCancel }) {
  return (
    <div className="fixed z-10 overflow-y-scroll top-0 right-0 mx-auto left-0">
      <div className="flex items-center justify-center min-h-[80vh] pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <div className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-gray-200 flex flex-col justify-center items-center gap-4 w-full px-4 py-3 text-center">
            <h2 className="font-semibold text-lg">Are you sure?</h2>
            <div className="flex py-2">
              <button
                onClick={onCancel}
                type="button"
                className="py-2 px-4 border text-black border-teal-400 outline-none rounded hover:bg-teal-700 hover:text-white mr-2 shadow"
              >
                Cancel
              </button>
              <button
                onClick={onConfirm}
                type="button"
                className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RePost
