import Main from "./Components/Home/Main";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact/Contact";
import About from "./Components/About/About";
import Service from "./Components/Services/Service";
import Bolgs from "./Components/Bolg/Bolgs";
import Pages from "./Components/Bolg/Pages";
import Login from "./Components/Login/Login";
import Post from "./Components/Backend/Post";
import { authActions } from "./Store";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import AddPost from "./Components/Backend/AddPost";
import Update from "./Components/Backend/Update";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const dispatch = useDispatch();
  //const isLoggedIn = useSelector((state)=> state.isLoggedIn);
  useEffect(() => {
    const userId = localStorage.getItem("id");
    userId && dispatch(authActions.login());
  }, [dispatch]);

  return (
    <div className="">
      <ToastContainer />
      <Navbar />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Service />} />
        <Route path="/blog" element={<Bolgs />} />
        <Route path="/blogs/:id" element={<Pages />} />
        <Route path="/login-staff" element={<Login />} />
        <Route path="/posts" element={<Post />} />
        <Route path="/add" element={<AddPost />} />
        <Route path="/update/:id" element={<Update />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
