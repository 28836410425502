import HomeFour from "./HomeFour";
import HomeFive from "./HomeFive";
import HomeOne from "./HomeOne";
import HomeThree from "./HomeThree";
import HomeTwo from "./HomeTwo";
import HomeSix from "./HomeSix";
import Maps from "../ReUse/Maps";
import Discuss from "./Discuss";

const Main = () => {
  return (
    <div className="overflow-hidden">
       <HomeOne />   
       <HomeTwo /> 
       <HomeThree />
       <Discuss />
       <HomeFour />
       <HomeFive />
       <HomeSix />
       <Maps />
    </div>
  )
}

export default Main;
