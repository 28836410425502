import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Update = () => {

  const { id } = useParams();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [input, setInput] = useState({
    title: "",
    description: "",
    subTitle: "",
    subDescription: "",
    imageUrl: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    setInput((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    let unSubscribe = false;
    const defaultInput = async () => {
      const res = await axios.get(`${process.env.REACT_APP_URL}${id}`, {
        headers: { authorization: token },
      });
      const data = res.data.postById;
      setInput({
        title: data.title,
        description: data.description,
        subTitle: data.subTitle,
        subDescription: data.subDescription,
        imageUrl: data.imageUrl,
      });
      return data;
    };
    if (!unSubscribe) {
      defaultInput();
    }
    return () => (unSubscribe = true);
  }, [id, token]);

  const sendRequest = async () => {
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}update/${id}`,
        {
          title: input.title,
          description: input.description,
          imageUrl: input.imageUrl,
          subDescription: input.subDescription,
          subTitle: input.subTitle,
        },

        {
          headers: { authorization: token },
        }
      );
      const data = await res.data;
      data && toast.success("Post updated successfully!")
      //window.location.reload();
      navigate("/posts");
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendRequest();
  };


  return (
    <div className="container mx-auto flex flex-col flex justify-center items-center py-12">
      <div className="w-full font-semibold tracking-wider text-2xl flex justify-center items-center rounded flex h-[150px] bg-teal-300">
        Update Post
      </div>
      <div className="flex w-full px-8 flex-col md:w-1/2 mt-8">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-center gap-3 items-start mt-4"
        >
          <div className="flex w-full gap-3 flex-col">
            <label className="text-lg font-semibold" htmlFor="name">
              {" "}
              Title:{" "}
            </label>
            <input
              name="title"
              type="text"
              value={input.title}
              onChange={handleChange}
              className="p-3 outline-none rounded border-teal-300 border"
              placeholder="Enter Blog Title"
              required
            />
          </div>
          <div className="flex w-full gap-3 flex-col">
            <label className="text-lg font-semibold" htmlFor="name">
              {" "}
              Sub Title:{" "}
            </label>
            <input
              name="subTitle"
              type="text"
              value={input.subTitle}
              onChange={handleChange}
              className="p-3 outline-none rounded border-teal-300 border"
              placeholder="Enter Blog Sub-Title"
              required
            />
          </div>
          <div className="flex w-full gap-3 flex-col">
            <label className="text-lg font-semibold" htmlFor="name">
              {" "}
              Image Url:{" "}
            </label>
            <input
              name="imageUrl"
              type="url"
              value={input.imageUrl}
              onChange={handleChange}
              className="p-3 outline-none rounded border-teal-300 border"
              placeholder="https://example.com"
              required
            />
          </div>
          <div className="flex w-full gap-3 flex-col">
            <label className="text-lg font-semibold" htmlFor="name">
              {" "}
              Description:{" "}
            </label>
            <textarea
              name="description"
              type="text"
              value={input.description}
              onChange={handleChange}
              className="p-3 outline-none rounded border-teal-300 border h-[150px]"
              placeholder="Enter Blog Description"
              required
            />
          </div>
          <div className="flex w-full gap-3 flex-col">
            <label className="text-lg font-semibold" htmlFor="name">
              Sub Description:{" "}
            </label>
            <textarea
              name="subDescription"
              type="text"
              value={input.subDescription}
              onChange={handleChange}
              className="p-3 outline-none rounded border-teal-300 border h-[150px]"
              placeholder="Enter Blog Sub-Description"
              required
            />
          </div>
          <button
            onClick={handleSubmit}
            className="bg-teal-300 mt-3 p-3 w-[140px] text-white hover:bg-black transition duration-500 ease-in-out rounded border border-black hover:border-teal-300"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Update;
