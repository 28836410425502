import React, { useState } from "react";
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";
//import { authActions } from "../../Store";
import { useDispatch, useSelector } from "react-redux";

export default function Navbar() {

  const dispatch = useDispatch();
  const userId = useSelector((state)=> state.isLoggedIn)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className="w-full realtive sm:px-3 bg-black border-b border-teal-400">
      <nav className="relative sm:px-2 py-4 z-10">
        <DesktopMenu
          onDropdownToggle={() => setIsDropdownOpen(!isDropdownOpen)}
          isDropdownOpen={isDropdownOpen}
          userId = {userId}
          dispatch = {dispatch}
        />

        <MobileMenu
          isMenuOpen={isMenuOpen}
          onDropdownToggle={() => setIsDropdownOpen(!isDropdownOpen)}
          onMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
          isDropdownOpen={isDropdownOpen}
          userId = {userId}
          dispatch = {dispatch}
        />
      </nav>
    </div>
  );
}
