import React from 'react';
import { ReactComponent as Rocket } from "../../Assets/seo.svg";
import {motion} from "framer-motion";
import { fadeIn, textVariant } from '../FramerMotion/styles';

const AboutThree = () => {
  return (
    <div className="container mx-auto p-6">
      <motion.h2
        variants={textVariant()}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
        className="text-6xl font-semibold py-8"
      >
        Our Valuable Clients
      </motion.h2>

      <div className="flex flex-wrap gap-3 py-4">
        <motion.div
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          variants={fadeIn("left", "", 0.1, 0.45)}
          className="flex-1"
        >
          <Rocket />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          variants={fadeIn("left", "", 0.3, 0.55)}
          className="flex-1"
        >
          <Rocket />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          variants={fadeIn("left", "", 0.6, 0.65)}
          className="flex-1"
        >
          <Rocket />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          variants={fadeIn("left", "", 0.9, 0.75)}
          className="flex-1"
        >
          <Rocket />
        </motion.div>
      </div>
    </div>
  );
}

export default AboutThree
