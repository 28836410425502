import React, {useState, useEffect} from 'react';
import axios from "axios";
import RePost from '../ReUse/RePost';
import { toast } from 'react-toastify';

const Post = () => {
  
  const [input, setInput] = useState();
  const [loading, setLoading] = useState(false);
  
  useEffect(()=>{
  
    let unSubscribe = false;
    setLoading(true);
    const sendRequest = async () =>{
      const res = await axios.get(process.env.REACT_APP_URL);
      const data = await res.data;
      loading && toast.loading("Loading Posts...")
      setInput(data.allPost);
      return data;
    }
    if(!unSubscribe){
      sendRequest();
      setLoading(false);
    }
    return ()=> {unSubscribe = true}
  }, [loading])

 //input && console.log(input);


  return (
    <div className="w-screen py-12">
      <div className="container mx-auto flex justify-center items-center px-3 min-h-[80vh]">
        <div className="grid grid-cols-1 gap-x-6 gap-y-12 lg:grid-cols-3">
          {
            input?.map((inp, index) =>{
             // const {title, description, subTitle, subDescription, users} = inp;
              return (
                <RePost key={index} {...inp} />
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

export default Post
